import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    uid: "",
    name: "",
  },
  getters: {
    userId: (state) => {
      return state.uid;
    },
    userName: (state) => {
      return state.name;
    },
  },
  mutations: {
    logout(state) {
      state.uid = "";
      state.name = "";
    },
    UPDATE_USER_ID(state, user) {
      state.uid = user;
    },
    UPDATE_USER_NAME(state, name) {
      state.name = name;
    },
  },
  actions: {
    LOGOUT({ commit }, dec) {
      commit("logout", dec);
    },
    CREATE_USER_ID({ commit }, uid) {
      commit("UPDATE_USER_ID", uid);
    },
    CREATE_USER_NAME({ commit }, uname) {
      commit("UPDATE_USER_NAME", uname);
    },
  },
  modules: {},
});
