import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
import install from "./install";
import i18n from "./i18n";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAbv1dO6YD8mlIToDhwdbGjpFAbEujbppQ",
  authDomain: "rema-da2f4.firebaseapp.com",
  databaseURL: "https://rema-da2f4.firebaseio.com",
  projectId: "rema-da2f4",
  storageBucket: "rema-da2f4.appspot.com",
  messagingSenderId: "822161756374",
  appId: "1:822161756374:web:99bef13fd6195ed414f977",
  measurementId: "G-Q5H76H6TJY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore();

createApp(App)
  .use(i18n)
  .use(install)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(store)
  .use(router)
  .mount("#app");
