import "./assets/style/css/tailwind.css";
import "./assets/style/sass/main.scss";
import "./assets/style/sass/mixin.scss";
import Vue3PersianDatetimePicker from "vue3-persian-datetime-picker";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  install(app) {
    app.component("DatePicker", Vue3PersianDatetimePicker);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Datepicker", Datepicker);
  },
};
